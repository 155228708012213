var twentyFourHours = 24 * 60 * 60 * 1000;

function showPopup() {
  var popupBackground = document.querySelector('.popup-background');
  if (popupBackground) {
    popupBackground.style.display = 'flex';
    setTimeout(function () {
      popupBackground.classList.add('active');
    }, 10);
  }
}

function closePopup() {
  var popupBackground = document.querySelector('.popup-background');
  if (popupBackground) {
    popupBackground.classList.remove('active');
    setTimeout(function () {
      popupBackground.style.display = 'none';
    }, 500);
  }

  var now = new Date().getTime();
  localStorage.setItem('lastPopupTime', now);
}

document.addEventListener('DOMContentLoaded', function () {
  if (document.body.classList.contains('popup-enabled')) {
    var closeButton = document.querySelector('.popup__close');
    if (closeButton) {
      closeButton.addEventListener('click', closePopup);
    }

    var lastPopupTime = localStorage.getItem('lastPopupTime');
    var now = new Date().getTime();
    var twentyFourHours = 24 * 60 * 60 * 1000;

    if (!lastPopupTime || now - lastPopupTime > twentyFourHours) {
      window.addEventListener('scroll', checkScrollDepth);
      document.addEventListener('mouseout', checkExitIntent);
      inactivityTime();
    }

    var popupBackground = document.querySelector('.popup-background');
    if (popupBackground) {
      popupBackground.addEventListener('click', function (event) {
        if (event.target === popupBackground) {
          closePopup();
        }
      });
    }

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        closePopup();
      }
    });
  }
});

function checkScrollDepth() {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var docHeight = document.documentElement.scrollHeight;
  var winHeight = window.innerHeight;
  var scrolled = (scrollTop / (docHeight - winHeight)) * 100;

  if (scrolled > 50) {
    showPopup();
    window.removeEventListener('scroll', checkScrollDepth);
  }
}

function checkExitIntent(e) {
  if (e.clientY <= 0) {
    var lastPopupTime = localStorage.getItem('lastPopupTime');
    var now = new Date().getTime();
    var twentyFourHours = 24 * 60 * 60 * 1000;
    if (!lastPopupTime || now - lastPopupTime > twentyFourHours) {
      showPopup();
    }
  }
}
document.addEventListener('mouseout', checkExitIntent);

function showInactivityPopup() {
  var lastPopupTime = localStorage.getItem('lastPopupTime');
  var now = new Date().getTime();
  if (!lastPopupTime || now - lastPopupTime > twentyFourHours) {
    showPopup();
  }
}

var inactivityTime = function () {
  var time;
  var maxInactivityTime = 60 * 300;

  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(showInactivityPopup, maxInactivityTime);
  }

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
};

inactivityTime();